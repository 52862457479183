<template>
  <div class="card card-custom mb-3">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label cardLabelTiket">Support Tickets</h3>
      </div>
      <div class="form-group float-right mt-5">
        <b-form-select
          v-model="fillteredsupport"
          :options="optionspending"
          @change="pendingFilter"
        >
        </b-form-select>
      </div>
    </div>
    <div class="anyClasssite">
      <b-tabs pills card>
        <div
          class="card mt-4 mb-4 rounded"
          style="margin: 20px; cursor:pointer"
          @click="detailSupport(items)"
          v-for="(items, index) in site_list"
          :key="index"
        >
          <!-- Nama Site -->
          <b-row style="margin: 10px">
            <b-col cols="9"
              ><strong style="color: var(--textlabelkuning)">{{
                items.site_location
              }}</strong></b-col
            >
            <!--                  <b-col></b-col>-->
            <b-col cols="3">{{ items.number_of_tickets }} Problem</b-col>
          </b-row>
        </div>
      </b-tabs>
    </div>
    <div class="card-footer text-muted">
      <center>
        <router-link to="/viewsupporttiket">
          <label style="font-weight: normal;cursor: pointer;">
            Lihat Semua</label
          >
        </router-link>
      </center>
    </div>
    <b-modal
      v-model="modalShow"
      size="xl"
      ref="my-modaledit"
      :title="title_site"
      hide-footer
      centered
    >
      <b-container class="bv-example-row">
        <!-- <b-button class="btn btn-danger" type="submit" @click="closeAll()"
          >Close All Ticket</b-button
        > -->
        <b-form @submit.stop.prevent="onSubmitEdit">
          <div class="row">
            <div class="col-12">
              <DxDataGrid
                :ref="dataGridRefKey"
                :data-source="dataSourceView"
                key-expr="id"
                :show-borders="true"
                :word-wrap-enabled="true"
                :column-auto-width="true"
                :remote-operations="true"
                :column-hiding-enabled="false"
                @row-click="getListTicket"
              >
                <DxSearchPanel :visible="true" />
                <DxFilterRow :visible="false" />
                <DxColumn data-field="ticket_id" caption="Ticket ID" />
                <DxColumn data-field="site_id" caption="Site ID" />
                <DxColumn data-field="site_location" caption="Location" />
                <DxColumn data-field="problem" caption="Problem" />
                <DxColumn
                  data-field="created_at"
                  caption="Tanggal"
                  data-type="date"
                  format="dd/MM/yyyy HH:mm:ss"
                />
                <DxColumn data-field="status" caption="Status" />
                <DxColumn data-field="description" caption="Description" />
                <DxColumn data-field="description_support" caption="Description Support" />
                <DxColumn :width="100" type="buttons" caption="Aksi">
                    <DxButton icon="fa fa-edit" hint="Edit" :onClick="editHistoryTicket" />
                    <!-- <DxButton icon="fa fa-trash" hint="Hapus" :onClick="hapusTicket" /> -->
                </DxColumn>
                <DxPaging :page-size="10" />
                <DxPager
                  :visible="true"
                  :show-page-size-selector="showPageSizeSelector"
                  :allowed-page-sizes="row"
                />
              </DxDataGrid>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <b-form-group
                id="code-input-group"
                label="Nama"
                label-for="saber"
              >
                <b-form-input
                  id="saber"
                  name="saber"
                  placeholder="Nama"
                  v-model="formEdit.saber"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-5">
              <b-form-group
                id="code-input-group"
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  name="email"
                  placeholder="Email"
                  v-model="formEdit.email"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-2" style="display: flex;
                    align-items: center;
                    justify-content: center;">
              <b-button
                    variant="outline-white"
                    style="
                      color: var(--textlabelkuning);
                      border-color: var(--textlabelkuning);
                    "
                    size="sm"
                    @click="getListTicketGroup()"
                  >
                    <div>See History</div>
                  </b-button>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <b-button class="mr-5" type="submit" @click="action = 'update'"
              >Update Ticket</b-button
            >
            <b-button
              class="btn btn-danger"
              type="submit"
              @click="action = 'closed'"
              >Close Tiket</b-button
            >
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <b-modal
        v-model="showFormUpdateTicket"
        size="xl"
        ref="my-modalFormUpdateTicket"
        title="Update Ticket"
        hide-footer
        centered
      >
        <b-form @submit.stop.prevent="submitUpdateTicket" class="mt-4">
          <div class="row">
            <div class="col-6">
              <b-form-group
                id="code-input-group"
                label="Site ID"
                label-for="site id"
              >
                <b-form-input
                  id="name"
                  disabled="true"
                  name="name"
                  placeholder="Nama"
                  v-model="formUpdateTicket.site_id"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                id="code-input-group"
                label="Location"
                label-for="Location"
              >
                <b-form-input
                  id="name"
                  name="name"
                  disabled="true"
                  placeholder="Nama"
                  v-model="formUpdateTicket.site_location"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                id="code-input-group"
                label="Problem"
                label-for="problem"
              >
                <b-form-input
                  id="problem"
                  name="problem"
                  disabled="true"
                  placeholder="problem"
                  v-model="formUpdateTicket.problem"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                id="code-input-group"
                label="Description"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  name="name"
                  placeholder="Description"
                  disabled="true"
                  v-model="formUpdateTicket.description"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                id="code-input-group"
                label="Catergory"
                label-for="category"
              >
                <b-form-input
                  id="category"
                  name="category"
                  placeholder="Category"
                  disabled="true"
                  v-model="formUpdateTicket.category_name"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                id="code-input-group"
                label="Subcatergory"
                label-for="subcategory"
              >
                <b-form-input
                  id="subcategory"
                  name="subcategory"
                  placeholder="Subcatergory"
                  disabled="true"
                  v-model="formUpdateTicket.subcategory_name"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for=""
                  >Description Support: <span class="text-danger">*</span></label
                >
                <b-form-textarea
                  id="description_support"
                  v-model="$v.formUpdateTicket.description_support.$model"
                  :state="validateStateformUpdateTicket('description_support')"
                  aria-describedby="input-1-live-feedback"
                  placeholder="Fill out this form..."
                  required
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <b-button
              class="mr-5"
              type="submit"
              >Update</b-button
            >
          </div>
        </b-form>
        <!-- <pre>{{ infoModal.content }}</pre> -->
      </b-modal>
    <b-modal
      v-model="showModalHistoryTicket"
      size="custom-size"
      ref="my-modalHistoryTicket"
      :title="ticketSelected ? 'Tikect History : '+ticketSelected.ticket_id : ''"
      hide-footer
      centered
    >
      <div class="row">
        <div class="col-12">
          <div class="card-body">
            <DxDataGrid
              :ref="dataGridRefKeyHistory"
              :data-source="dataSourceHistoryTicket"
              key-expr="id"
              :show-borders="true"
              :word-wrap-enabled="false"
              :column-auto-width="false"
              :remote-operations="true"
              :column-hiding-enabled="false">
              <DxColumn data-field="ticket_id" caption="Ticket ID" />
              <DxColumn data-field="category_name" caption="Category" />
              <DxColumn data-field="subcategory_name" caption="Sub Category" />
              <DxColumn data-field="description" caption="Decsription" />
              <DxColumn data-field="description_support" caption="Decsription Support" />
              <DxColumn
                  data-field="updated_at"
                  caption="Tanggal"
                  data-type="date"
                  format="dd/MM/yyyy HH:mm:ss"
              />
              <DxColumn data-field="user.role.name" caption="Updated By" />
            </DxDataGrid>
            <DxPaging
                :page-size="5"
                :page-index="0"
            />
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-model="showModalHistoryTicketGroup"
      size="xl"
      ref="my-modalHistoryTicket"
      :title="ticketSelectedGroup ? 'Tikect History : '+ticketSelectedGroup.ticket_id : ''"
      hide-footer
      centered
    >
      <div class="row">
        <div class="col-12">
          <div class="card-body">
            <DxDataGrid
              :ref="dataGridRefKeyHistoryGroup"
              :data-source="dataSourceHistoryTicketGroup"
              key-expr="id"
              :show-borders="true"
              :word-wrap-enabled="false"
              :column-auto-width="false"
              :remote-operations="true"
              :column-hiding-enabled="false">
              <DxColumn data-field="ticket_id" caption="Ticket ID" />
              <DxColumn data-field="saber" caption="Nama" />
              <DxColumn data-field="email" caption="Email" />
              <DxColumn
                  data-field="updated_at"
                  caption="Tanggal"
                  data-type="date"
                  format="dd/MM/yyyy HH:mm:ss"
              />
              <DxColumn data-field="user.role.name" caption="Updated By" />
            </DxDataGrid>
            <DxPaging
                :page-size="5"
                :page-index="0"
            />
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import WebService from "../../core/services/api";
import {
  DxButton,
  DxColumn,
  DxDataGrid,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxSearchPanel
} from "devextreme-vue/data-grid";
import { validationMixin } from "vuelidate";
import EventBus from "../../core/services/common/EventBus";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import { isNotEmpty } from "../../core/Apputil/Dx";
import CustomStore from "devextreme/data/custom_store";
const dataGridRefKey = "my-data-grid-view";
const dataGridRefKeyHistory = "my-data-grid-histrory";
const dataGridRefKeyHistoryGroup = "my-data-grid-histrory-group";
export default {
  mixins: [validationMixin],
  name: "site_list",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DxButton,
    DxSearchPanel,
    DxColumn,
    DxDataGrid,
    DxFilterRow,
    DxPager,
    DxPaging
  },
  props: {
    msg: String
  },

  data() {
    return {
      formEdit: {
        id: null,
        site_id: null,
        site_location: "",
        problem: "",
        description: "",
        status: "",
        email: "",
        saber: "",
        category: "",
        subcategory: ""
      },
      formUpdateTicket: {
        box_sn: "",
        category_id:0 ,
        category_name: "" ,
        closed_at: "",
        created_at: "",
        created_date: "",
        description: "",
        description_support: "",
        ditutup_via: "",
        email: "",
        id: 0,
        last_visit: "",
        old_ticket_id: "",
        problem: "",
        saber: "",
        site_id: "",
        site_location: "",
        status: "",
        subcategory_id: 0,
        subcategory_name: "",
        ticket_id: "",
        ticket_id_penutup: "",
        updated_at: ""
      },
      dataGridRefKey,
      dataGridRefKeyHistory,
      dataGridRefKeyHistoryGroup,
      action: null,
      selectAll: false,
      records: [],
      allOpenRows: [],
      dataSourceView: [],
      item: [],
      SubCategories: [],
      fillteredsupport: "DESC",
      perPage: 10,
      showModalEdit: false,
      showModalEditEmail: false,
      modalShow: false,
      visible: false,
      selectedpending: "ASC",
      title_site: "-",
      optionspending: [
        { value: "DESC", text: "Tertinggi" },
        { value: "ASC", text: "Terendah" }
      ],
      site_list: [],
      itemseditearly: [],
      fieldseditearly: [
        {
          key: "user",
          label: "User",
          class: "text-center"
        },
        {
          key: "old_data",
          label: "Category",
          sortable: false,
          class: "text-center"
        },
        {
          key: "created_at",
          label: "Created At",
          sortable: false,
          class: "text-center"
        },
        {
          key: "entity",
          label: "Time of Update",
          sortable: false,
          class: "text-center"
        },
        {
          formatter: value => {
            return value ? "Yes" : "No";
          },

          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        }
      ],
      itemsticket: [],
      wrongEmail: false,
      fieldsticket: [
        {
          key: "no",
          label: "No"
        },
        {
          key: "ticket_id",
          label: "Ticket ID",
          sortable: true,
          class: "text-center"
        },
        {
          key: "category",
          label: "Category",
          sortable: true,
          class: "text-center"
        },
        {
          key: "sub_category",
          label: "Sub Category",
          sortable: true,
          class: "text-center"
        },
        {
          key: "update_time",
          label: "Update Time",
          sortable: false,
          class: "text-center"
        },
        {
          key: "operator",
          label: "Operator",
          sortable: true,
          class: "text-center"
        },
        {
          key: "saber",
          label: "Saber",
          sortable: true,
          class: "text-center"
        },
        {
          key: "status",
          label: "status",
          sortable: true,
          class: "text-center"
        },

        {
          formatter: value => {
            return value ? "Yes" : "No";
          },

          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        }
        // { key: "actions", label: "Actions", class: "text-center" },
      ],
      showFormUpdateTicket: false,
      ticketSelected: null,
      dataSourceHistoryTicket: [],
      showModalHistoryTicket: false,
      ticketSelectedGroup: null,
      dataSourceHistoryTicketGroup: [],
      showModalHistoryTicketGroup: false
    };
  },
  validations: {
    formEdit: {
      id: {
        required
      }
    },
    formUpdateTicket: {
      id: { required },
      description_support: { required }
    }
  },
  computed: {
    hasRecords() {
      return this.records.length > 0;
    },
    totalRows() {
      return this.records.length;
    }
  },
  mounted() {
    this.getDataTablenew();
    this.getCategories();
  },
  methods: {
    validateStateFormEdit(name) {
      const { $dirty, $error } = this.$v.formEdit[name];
      return $dirty ? !$error : null;
    },
    validateStateformUpdateTicket(name) {
      const { $dirty, $error } = this.$v.formUpdateTicket[name];
      return $dirty ? !$error : null;
    },
    editHistoryTicket(e) {
      const ticketSelected = e.row.data;
      this.ticketSelected = null;
      this.formUpdateTicket = Object.assign({}, this.formUpdateTicket, ticketSelected);
      this.formUpdateTicket.description_support = '';
      this.showFormUpdateTicket = true;
      this.showModalHistoryTicket = false;
      console.log(this.formUpdateTicket);
    },
    submitUpdateTicket(e){
      e.preventDefault();
      let postBody = Object.assign({}, this.formUpdateTicket);
      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.putNewTicket(postBody.id, postBody)
            .then(data => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil diubah",
                showConfirmButton: false,
                timer: 1500
              }).finally(() => {
                if(data.data.data.id){
                  this.getListTicket(data.data);
                  this.detailSupport(this.ticketSelectedGroup)
                }
              });
              this.$v.formUpdateTicket.$reset();
              this.$refs["my-modalFormUpdateTicket"].hide();
              this.showFormUpdateTicket = false;
            })
            .catch(() => {
              Swal.fire({
                title: "Kesalahan",
                text: "Terjadi Kesalahan",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    getListTicket(e) {
      this.ticketSelected = e.data;
      const store = new CustomStore({
        key: "id",
        load: function(loadOptions) {
          loadOptions['take'] = 1000;
          let params = "?";
          [
            "skip",
            "take",
            "requireTotalCount",
            "requireGroupCount",
            "sort",
            // "filter",
            "totalSummary",
            "group",
            "groupSummary"
          ].forEach(function(i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) {
              params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            }
          });
          params = params.slice(0, -1);

          // search by name if exists

          if (
            Array.isArray(loadOptions["filter"]) &&
            loadOptions["filter"].length > 0 &&
            loadOptions["filter"][0] === "name"
          ) {
            params += "&nama=" + loadOptions["filter"][2];
          }
          const user = JSON.parse(localStorage.getItem("user"));
          const headers = { Authorization: `Bearer ${user.access_token}` };
          return fetch(
            process.env.VUE_APP_URL_LOCAL +
              `/ticket-new/history/${e.data.id}${params}`,
            {
              headers
            }
          )
            .then(response => response.json())
            .then(data => {
              let tempdata = [];
              if(data.data?.length > 0){
                for(let i = data.data?.length - 1; i > 0; i--){
                  let tempPush = { ...data.data[i].new_data, user: JSON.parse(data.data[i].user)};
                  tempdata.push(tempPush);
                }
              }
              return {
                data: tempdata,
                summary: data.summary,
                totalCount: data.totalCount
              };
            })
            .catch(() => {
              throw "Data Loading Error";
            });
        },
        onLoaded: (resp) => {
          if(resp.data.length){
            resp.data.map((fd) => {
              if(fd.sub_category){
                fd['subcategory_name'] = fd.sub_category.name;
                if(fd.sub_category.category){
                  fd['category_name'] = fd.sub_category.category.name
                }
              } else {
                fd['category_name'] = '';
                fd['subcategory_name'] = '';
              }
            });
          }
        }
      });
      this.dataSourceHistoryTicket = store;
      this.showModalHistoryTicket = true;
    },
    getListTicketGroup(){
      WebService.getListTicketSupport(this.ticketSelectedGroup.id)
      .then(resp => {
        let tempdata = [];
        if(resp.data.data?.length > 0){
          for(let i = resp.data.data.length - 1; i >= 0; i--){
            let tempPush = { ...resp.data.data[i].new_data, user: JSON.parse(resp.data.data[i].user)};
            tempdata.push(tempPush);
            // tempdata.push(resp.data.data[i].new_data);
          }
          this.dataSourceHistoryTicketGroup = tempdata;
        } else {
          this.dataGridRefKeyHistoryGroup = [];
        }
        if(this.dataSourceHistoryTicketGroup.length == 1){
          this.dataSourceHistoryTicketGroup = [];
        } else {
          this.dataSourceHistoryTicketGroup = this.dataSourceHistoryTicketGroup.slice(0, this.dataSourceHistoryTicketGroup.length -1);
        }
        this.showModalHistoryTicketGroup = true;
      });
    },
    format_date(value) {
      if (value) {
        return moment(String(value))
          .lang("id")
          .format("DD MMMM YYYY hh:mm");
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.onSubmitEdit();
    },
    getCategories() {
      WebService.getCategoryAll().then(
        res => {
          const categories = res.data.data;
          let selectedOptions;
          selectedOptions = [];
          categories.map(i => {
            return selectedOptions.push({
              text: i.name,
              value: i.id
            });
          });
          this.categories2 = selectedOptions;
        },
        err => {
          console.log(err);
        }
      );
    },
    getCategory(id) {
      WebService.getSubCategoryById(id).then(
        res => {
          const cat = res.data.data;
          let selectedOptions;
          selectedOptions = [];
          cat.map(i => {
            return selectedOptions.push({
              text: i.name,
              value: i.id
            });
          });
          this.SubCategories = selectedOptions;
        },
        err => {
          console.log(err);
          this.SubCategories = [];
        }
      );
    },
    isEmailValid() {
      if (/^[^@]+@\w+(\.\w+)+\w$/.test(this.formEdit.email)) {
        this.wrongEmail = false;
      } else {
        this.wrongEmail = true;
      }
    },
    onSubmitEdit() {

      // this.$v.formEdit.$touch();
      // if (this.$v.formEdit.$anyError) {
      //   return;
      // }
      let id = this.formEdit.id;
      let data = {
        id: this.formEdit.id,
        site_id: this.formEdit.site_id,
        site_location: this.formEdit.site_location,
        problem: this.formEdit.problem,
        description: this.formEdit.description,
        status: this.action,
        category_id: this.formEdit.category,
        subcategory_id: this.formEdit.subcategory,
        saber: this.formEdit.saber,
        email: this.formEdit.email,
        box_sn: this.detailsupport.box_sn,
        ticket_id: this.detailsupport.ticket_id
      };
      if(this.formEdit.email != "" && this.formEdit.email != null){
        if (/^[^@]+@\w+(\.\w+)+\w$/.test(this.formEdit.email)) {
          Swal.fire({
            title: "Konfirmasi",
            text: "Pastikan kembali data sudah sesuai.",
            icon: "warning",
            iconHtml: "?",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonColor: "#18328D",
            confirmButtonText: "Simpan",
            cancelButtonText: "Batal",
            showLoaderOnConfirm: true,
            preConfirm: () => {
              // if (this.action == "update") {
              //   WebService.SendEmail(BodyEmail).then(res => {
              //     console.log(res.data.message);
              //   });
              // }
              return WebService.editTiketprocessTiket(id, data)
                .then(data => {
                  if (data.status == 200) {
                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: "Data Berhasil Dirubah",
                      showConfirmButton: false,
                      timer: 1500
                    });
                    this.resetFormEdit();
                    this.modalShow = false;
                    //callback delete support tiket
                    if (this.action == "closed") {
                      console.log("delete data");
                    }
                  } else {
                    Swal.fire({
                      title: "Kesalahan",
                      text: "Data Gagal Dirubah",
                      icon: "error",
                      showCancelButton: false,
                      confirmButtonText: "Kembali",
                      confirmButtonColor: "#18328D"
                    });
                    this.modalShow = false;
                  }
                  this.showModalEdit = false;
                  this.showModalEditEmail = false;
                })
                .catch(error => {
                  console.log(error);
                });
            },
            allowOutsideClick: () => !Swal.isLoading()
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Email Tidak Valid!",
            showConfirmButton: false,
            timer: 1500
          });
        }
      } else {
        Swal.fire({
          title: "Konfirmasi",
          text: "Pastikan kembali data sudah sesuai.",
          icon: "warning",
          iconHtml: "?",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonColor: "#18328D",
          confirmButtonText: "Simpan",
          cancelButtonText: "Batal",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            // if (this.action == "update") {
            //   WebService.SendEmail(BodyEmail).then(res => {
            //     console.log(res.data.message);
            //   });
            // }
            return WebService.editTiketprocessTiket(id, data)
              .then(data => {
                if (data.status == 200) {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Data Berhasil Dirubah",
                    showConfirmButton: false,
                    timer: 1500
                  });
                  this.resetFormEdit();
                  this.modalShow = false;
                  //callback delete support tiket
                  if (this.action == "closed") {
                    console.log("delete data");
                  }
                } else {
                  Swal.fire({
                    title: "Kesalahan",
                    text: "Data Gagal Dirubah",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "Kembali",
                    confirmButtonColor: "#18328D"
                  });
                  this.modalShow = false;
                }
                this.showModalEdit = false;
                this.showModalEditEmail = false;
              })
              .catch(error => {
                console.log(error);
              });
          },
          allowOutsideClick: () => !Swal.isLoading()
        });
      }
      
    },
    resetFormEdit() {
      this.formEdit = {
        id: null,
        site_id: null,
        site_location: "",
        problem: "",
        description: "",
        status: ""
      };

      this.$nextTick(() => {
        this.$bvModal.hide("edit-user-modal");
      });
    },
    iconEdit(e) {
      console.log(e);
      WebService.gethistorytiketSupportTiket(e.row.data.ticket_id)
        .then(res => {
          this.itemseditearly = res.data.data;
          console.log(this.itemseditearly);
        })
        .catch(error => {
          console.log(error);
          this.itemseditearly = [];
        });
      const data = e.row.data;
      console.log(data);
      if (data.status == "pending") {
        this.showModalEditEmail = true;
      } else {
        this.showModalEdit = true;
      }
      this.detailEdit = data;
      this.formEdit.id = data.id;
      this.formEdit.site_id = data.site_id;
      this.formEdit.site_location = data.site_location;
      this.formEdit.problem = data.problem;
      this.formEdit.description = data.description;
      this.formEdit.status = data.status;
      this.formEdit.category = data.category_id;
      this.formEdit.subcategory = data.subcategory_id;
      this.formEdit.status = data.status;
      this.formEdit.saber = data.saber;
      this.formEdit.email = data.email;
      if (data.category_id !== 0) {
        this.getCategory(data.category_id);
      }
    },
    handleRowClicked(item) {
      this.allOpenRows.map(ele => {
        if (ele.id !== item.id && ele._showDetails) {
          this.$set(ele, "_showDetails", !ele._showDetails);
        }
      });
      this.allOpenRows = [];
      this.$set(item, "_showDetails", !item._showDetails);
      this.allOpenRows.push(item);
    },
    pendingFilter(data) {
      console.log(data);
      this.fillteredsupport = data;
      this.getDataTablenew();
    },
    getDataTablenew() {
      this.loading = true;
      WebService.getshorttiketSupport(this.fillteredsupport).then(
        res => {
          this.site_list = res.data.data;
          this.totalRows = res.data.totalCount;
          this.loading = false;
        },
        error => {
          console.log(error);
          this.site_list = [];
          this.totalRows = 0;
          this.loading = false;
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
      this.loading = false;
    },
    detailSupport(items) {
      this.ticketSelectedGroup = items;
      this.modalShow = true;
      this.title_site =
        "Location Name : " +
        items.site_location +
        " | Site ID : " +
        items.site_id;
      const store = new CustomStore({
        key: "id",
        load: function(loadOptions) {
          let params = "?";
          [
            "skip",
            "take",
            "requireTotalCount",
            "requireGroupCount",
            "sort",
            // "filter",
            "totalSummary",
            "group",
            "groupSummary"
          ].forEach(function(i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) {
              params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            }
          });
          params = params.slice(0, -1);

          // search by name if exists

          if (
            Array.isArray(loadOptions["filter"]) &&
            loadOptions["filter"].length > 0 &&
            loadOptions["filter"][0] === "name"
          ) {
            params += "&nama=" + loadOptions["filter"][2];
          }
          const user = JSON.parse(localStorage.getItem("user"));
          const headers = { Authorization: `Bearer ${user.access_token}` };
          return fetch(
            process.env.VUE_APP_URL_LOCAL +
              `/ticket-support-items/${items.id}${params}`,
            {
              headers
            }
          )
            .then(response => response.json())
            .then(data => {
              return {
                data: data.data,
                totalCount: data.totalCount,
                summary: data.summary,
                groupCount: data.groupCount
              };
            })
            .catch(() => {
              throw "Data Loading Error";
            });
        },
        onLoaded: function(resp) {
          if(resp.data.length){
            resp.data.map((fd) => {
              if(fd.sub_category){
                fd['subcategory_name'] = fd.sub_category.name;
                if(fd.sub_category.category){
                  fd['category_name'] = fd.sub_category.category.name
                }
              } else {
                fd['category_name'] = '';
                fd['subcategory_name'] = '';
              }
            });
            console.log('datanya => ', resp);
          }
        }
      });
      this.dataSourceView = store;
      WebService.getsupportbyid(items.id).then(res => {
        this.getCategory(res.data.data.category_id);
        this.detailsupport = res.data.data;
        this.formEdit = {
          id: res.data.data.id,
          site_id: res.data.data.site_id,
          site_location: res.data.data.site_location,
          problem: res.data.data.problem,
          category: res.data.data.category_id,
          subcategory: res.data.data.subcategory_id,
          saber: res.data.data.saber,
          description: res.data.data.description,
          email: res.data.data.email,
          status: res.data.data.status
        };
      });
    },
    closeAll() {
      this.itemsticket.forEach(items => {
        const body = {
          id: items.id,
          created_at: items.created_at,
          updated_at: items.updated_at,
          ticket_id: items.ticket_id,
          site_id: items.site_id,
          site_location: items.site_location,
          category_id: items.category_id,
          subcategory_id: items.subcategory_id,
          created_date: items.created_date,
          last_visit: items.last_visit,
          problem: items.problem,
          description: items.description,
          status: "close"
        };
        console.log(body);
        WebService.editTiketPending(items.id, body).then(res => {
          console.log(res, "put");
          Swal.fire({
            position: "center",
            title: "Data berhasil Diubah",
            showConfirmButton: false,
            timer: 1500
          });
          this.modalShow = false;
        });
      });
    },
    hapus(index) {
      this.itemssaber.splice(index, 1);
    },
    getUser(data) {
      return JSON.parse(data.user).role.name;
    },
    getOperator(data) {
      return JSON.parse(data.user).email;
    },
    getDesc(data) {
      return JSON.parse(data.new_data).description;
    },
    getAlert(data) {
      return JSON.parse(data.new_data).problem;
    },
    getStatus(data) {
      return JSON.parse(data.new_data).status;
    },
    getCreatedat(data) {
      return moment(String(data.created_at))
        .lang("id")
        .format("DD MMMM YYYY HH:mm");
    },
    getUpdated(data) {
      return moment(String(JSON.parse(data.old_data).updated_at))
        .lang("id")
        .format("DD MMMM YYYY HH:mm");
    },
    hideModal() {
      this.$refs["my-modaledit"].hide();
    },
    hapusTicket(value) {
      const data = value.row.data;
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Yakin Data Dihapus?.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "OK",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.resetNewTicket(data.id)
            .then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil Dihapus",
                timer: 1500
              }).finally(() => {
                this.detailSupport(this.ticketSelectedGroup);
              });
            })
            .catch(() => {
              Swal.fire({
                title: "Kesalahan",
                text: "Data Gagal Dihapus",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
  },
  created() {
    this.interval = setInterval(() => {
      console.log("interval support tiket");
      this.getDataTablenew();
    }, 5000);
  },
  destroyed() {
    clearInterval(this.interval);
  }
};
</script>

<style>
.anyClasssite {
  height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}

.btn-tp {
  background: unset !important;
  border: unset !important;
  outline: unset !important;
}
.modal-dialog.modal-custom-size {
  max-width: 1300px !important;
}
</style>
