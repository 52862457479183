<template>
  <div class="card card-custom mb-3">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label cardLabelTiket">Ticketing Monitoring</h3>
      </div>
    </div>

    <div class="row no-gutters" style="justify-content: center !important">
      <div style="width: 100%">
        <div id="chart" style="min-height: 320px">
          <apexcharts
            ref="chart1"
            type="donut"
            :width="380"
            :height="380"
            :options="chartOptions"
            :series="chartOptions.series"
          ></apexcharts>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebService from "../../core/services/api";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      pendingData: 0,
      newData:0,
      chartOptions: {
        colors: ["#F97372", "#72F9F1"],
        stroke: {
          width: 0,
        },
        legend: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,
          itemMargin: {
            vertical: 10,
          },
          formatter: function (seriesName, opts) {
            return (
                '<div class="legend-info" style="color: var(--textlabelkuning)">' +
                "<span>" +
                seriesName +
                "</span><br>" +
                "<span>" +
                "Now it's " +
                opts.w.globals.series[opts.seriesIndex] +
                "</span>" +
                "</div>"
            );
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: ["New Ticket", "Pending Ticket"],

        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Rubik",
                  color: "var(--textlabelkuning)",
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  color: "var(--textlabelkuning)",
                  offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: "Priority Total",
                  color: "var(--textlabelkuning)",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a+b;
                    });
                  },
                },
              },
            },
          },
        },
        series: [100,100]
      },
    };
  },
  mounted() {
    this.getChart();
  },
  methods:{
    getChart() {
      WebService.getChartTiketing().then(
          (res) => {
            this.chartOptions.series = [parseInt(res.data.data[0].jumlah_new), parseInt(res.data.data[0].jumlah_pending)];

            // this.$refs.chart1.updateSeries([{
            //   name: 'Sales',
            //   series: [{
            //     data: [20,20]
            //   }],
            // }])
            console.log(res);
            // this.pendingData = res.data.data[0].jumlah_pending;
            // this.newData = res.data.data[0].jumlah_new;
          },
          (error) => {
            console.log(error);
            this.pendingData = 0;
            this.newData = 0;
          }
      );
      this.loading = false;
    },
  }
};
</script>

<style></style>
