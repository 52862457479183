<template>
  <div class="container-fluid ml-7">
    <div style="margin-top: 30px container-flex">
      <div class="row">
        <div class="col-xl-4">
          <div>
            <Timon />
          </div>
        </div>
        <div class="col-xl-8">
          <div>
            <div class="card card-custom mb-3">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label cardLabelTiket">Ticket Details</h3>
                </div>
              </div>

              <div class="row mt-5 container-fluid" style="min-height: 330px">
                <div class="col-4">
                  <div class="row" style="margin-bottom: 12px">
                    <div class="col-2">
                      <img
                        src="media/icon/dok_merah.png"
                        class="max-h-30px"
                        alt=""
                      />
                    </div>
                    <div class="col-10">
                      {{ detailTiket.site_id }}
                    </div>
                  </div>
                  <div class="row" style="margin-bottom: 12px">
                    <div class="col-2">
                      <img
                        src="media/icon/warning.png"
                        class="max-h-30px"
                        alt=""
                      />
                    </div>
                    <div class="col-6">
                      {{ detailTiket.problem }}
                    </div>
                  </div>
                  <div class="row" style="margin-bottom: 12px">
                    <div class="col-2">
                      <img
                        src="media/icon/location.png"
                        class="max-h-30px"
                        alt=""
                      />
                    </div>
                    <div class="col-10">
                      {{ detailTiket.site_location }}
                    </div>
                  </div>
                  <div
                    v-show="detailTiket.site_id !== '-'"
                    class="row"
                    style="margin-bottom: 12px"
                  >
                    <div class="col-2">
                      <img src="media/icon/dok.png" class="max-h-30px" alt="" />
                    </div>
                    <div class="col-10">
                      {{ format_date(detailTiket.created_date) }}
                    </div>
                  </div>
                  <div
                    v-show="detailTiket.site_id !== '-'"
                    class="row"
                    style="margin-bottom: 12px"
                  >
                    <div class="col-2">
                      <img
                        src="media/icon/stream.png"
                        class="max-h-30px"
                        alt=""
                      />
                    </div>
                    <div
                      class="col-8"
                      style="margin-top: -4px; margin-left: -9px"
                    >
                      <b-button
                        class="live"
                        style="color: var(--textlabelkuning)"
                        size="sm"
                        @click="showModalLive"
                        >Live Stream
                      </b-button>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <b-img
                    v-if="camerabase64"
                    :src="`data:image/png;base64,${camerabase64}`"
                    fluid
                    center
                    style="width: 90%; margin-bottom: 3%"
                    alt="Preview Tiket"
                    class="rounded"
                  ></b-img>
                  <b-img
                    v-else-if="!camerabase64"
                    src="media/icon/no-video-available.jpeg"
                    fluid
                    center
                    style="width: 70%; margin-bottom: 3%"
                    alt="Responsive image"
                    class="rounded"
                  ></b-img>
                </div>

                <div class="col-2" style="margin-bottom: 4%; margin-top: 4%">
                  <b-button
                    variant="outline-white"
                    style="
                      color: var(--textlabelkuning);
                      border-color: var(--textlabelkuning);
                    "
                    size="lg"
                    @click="detail = !detail"
                  >
                    <div>See Details</div>
                  </b-button>

                  <b-button
                    size="lg"
                    class="mt-4"
                    style="background-color: #c60b09; border-color: #c60b09"
                  >
                    <b-link to="/historyall">
                      <div class="text-light">
                        Ticket History
                        <b-icon-arrow-right></b-icon-arrow-right>
                      </div>
                    </b-link>
                  </b-button>
                </div>
                <!-- MODAL DETAIL  -->
                <b-modal
                  v-model="detail"
                  size="lg"
                  ref="my-modaledit"
                  title="Detail"
                  hide-footer
                >
                  <b-row class="mb-2">
                    <b-col cols="3">Site ID</b-col>
                    <b-col>
                      {{ detailTiket.site_id }}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="3">WS ID</b-col>
                    <b-col>
                      {{ detailTiket.wsid }}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="3">Location</b-col>
                    <b-col>
                      {{ detailTiket.site_location }}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="3">Problem</b-col>
                    <b-col>
                      {{ detailTiket.problem }}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="3">Box SN</b-col>
                    <b-col>
                      {{ detailTiket.box_sn }}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="3">Site Class Name</b-col>
                    <b-col>
                      {{ detailTiket.site_class_name }}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="3">Address</b-col>
                    <b-col>
                      {{ detailTiket.address }}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="3">Province</b-col>
                    <b-col>
                      {{ detailTiket.province }}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="3">District</b-col>
                    <b-col>
                      {{ detailTiket.district }}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="3">Zip Code</b-col>
                    <b-col>
                      {{ detailTiket.zip_code }}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="3">PKT</b-col>
                    <b-col>
                      {{ detailTiket.pkt }}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="3">Last Visit</b-col>
                    <b-col>
                      {{ format_date(detailTiket.last_visit) }}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="3">Created Date</b-col>
                    <b-col>
                      {{ format_date(detailTiket.created_date) }}
                    </b-col>
                  </b-row>
                </b-modal>
                <b-modal
                  size="lg"
                  title="Live Streaming"
                  hide-footer
                  centered
                  ref="my-modal"
                >
                  <template #modal-title>
                    Live Streaming
                  </template>
                  <div class="d-block text-center">
                    <iframe
                      :src="
                        `http://8.215.44.202:8014/api/sisnet/cctv-streaming/${detailTiket.box_sn}`
                      "
                      width="730"
                      height="500"
                      frameBorder="0"
                    ></iframe>
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div>
            <div class="card card-custom mb-3">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label cardLabelTiket">New Ticket</h3>
                </div>
              </div>
              <div class="anyClassnew container-fluid" id="infinite-list">
                <transition name="fade">
                  <div class="loading" v-show="loading">
                    <span class="fa fa-spinner fa-spin"></span> Loading
                  </div>
                </transition>
                <div
                  class="card mt-4 mb-4 contentTiket"
                  v-for="(item, index) in itemsuser"
                  :key="index"
                >
                  <div class="row">
                    <div
                      class="col-auto"
                      style="
                        width: 2px;
                        background-color: #1cb841;
                        margin-left: 15px;
                        border-radius: 16px 0px 0px 16px;
                      "
                    ></div>
                    <div class="col">
                      <div class="row">
                        <div
                          class="col-1"
                          style="
                            height: 20px;
                            margin-top: 12px;
                            margin-left: 3px;
                          "
                        >
                          <img
                            src="media/icon/tiket-success.png"
                            class="max-h-20px"
                            alt=""
                          />
                        </div>
                        <div
                          class="col-7"
                          style="height: 10px; margin-top: 12px"
                        >
                          <strong
                            class="txttiket"
                            v-on:click="selectTicketDetail(item)"
                            style="
                              font-weight: normal;
                              margin-left: -12px;
                              cursor: pointer;
                            "
                          >
                            {{ item.site_id }}</strong
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8">
                          <div style="margin-left: 32px; margin-top: 6px">
                            <strong
                              class="txttiket"
                              v-on:click="selectTicketDetail(item)"
                              style="font-weight: normal; cursor: pointer"
                            >
                              {{ item.ticket_id }}</strong
                            >
                          </div>
                        </div>
                        <div class="col-8">
                          <div style="margin-left: 32px; margin-top: 6px">
                            <strong
                              class="txttiket"
                              v-on:click="selectTicketDetail(item)"
                              style="font-weight: normal; cursor: pointer"
                            >
                              {{ item.site_location }}</strong
                            >
                          </div>
                        </div>
                        <div class="col-4">
                          <b-button
                            @click="selectTiket(item)"
                            style="
                              background: #d7dc09;
                              color: #2f2f37;
                              border-color: #d7dc09;
                            "
                            >Select</b-button
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div
                            style="
                              margin-left: 32px;
                              margin-top: 2px;
                              margin-bottom: 12px;
                            "
                          >
                            <strong
                              class="txttiket"
                              style="font-weight: normal"
                            >
                              {{ item.problem }} -
                              {{ format_date(item.created_at) }}</strong
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer text-muted">
                <center>
                  <router-link to="/viewnewtiket">
                    <label style="font-weight: normal;cursor: pointer;">
                      Lihat Semua</label
                    >
                  </router-link>
                </center>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div>
            <div class="card card-custom mb-3">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label cardLabelTiket">Pending Ticket</h3>
                </div>
                <div class="form-group float-right mt-5">
                  <b-button
                    style="
                      background-color: #c60b09;
                      color: white;
                      border-color: #c60b09;
                    "
                    @click="modalShow = !modalShow"
                    >Create New Ticket</b-button
                  >
                </div>
              </div>

              <div class="anyClasspend container-fluid">
                <div class="row" style="margin-top: 15px">
                  <div class="col">
                    <label class="mt-2">Early Progress</label>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <b-form-input
                        class=""
                        id="input-1"
                        type="text"
                        placeholder="Search for SiteID"
                        v-model="siteidtxt"
                        @change="searchAction"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col">
                    <b-form-select
                      v-model="selectedpending"
                      :options="optionspending"
                      @change="pendingFilter"
                    ></b-form-select>
                  </div>
                </div>
                <div
                  class="card mt-4 mb-4 contentTiket"
                  style="backgound-color: #800000"
                  v-for="(itempending, index) in itempending"
                  :key="index"
                >
                  <div class="row">
                    <div
                      class="col-auto"
                      style="
                        width: 2px;
                        background-color: #1cb841;
                        margin-left: 15px;
                        border-radius: 16px 0px 0px 16px;
                      "
                    ></div>
                    <div class="col">
                      <div class="row">
                        <div
                          class="col-1"
                          style="
                            height: 20px;
                            margin-top: 12px;
                            margin-left: 3px;
                          "
                        >
                          <img
                            src="media/icon/tiket-success.png"
                            class="max-h-20px"
                            alt=""
                          />
                        </div>
                        <div
                          class="col-7"
                          style="height: 10px; margin-top: 12px"
                        >
                          <label
                            style="font-weight: normal; margin-left: -12px;cursor: pointer;"
                            v-on:click="selectTicketDetail(itempending)"
                          >
                            {{ itempending.site_id }}</label
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-9">
                          <div style="margin-left: 32px; margin-top: 6px">
                            <label
                              v-on:click="selectTicketDetail(itempending)"
                              style="font-weight: normal;cursor: pointer;"
                            >
                              {{ itempending.ticket_id }}</label
                            >
                          </div>
                        </div>
                        <div class="col-9">
                          <div style="margin-left: 32px; margin-top: 6px">
                            <label
                              v-on:click="selectTicketDetail(itempending)"
                              style="font-weight: normal;cursor: pointer;"
                            >
                              {{ itempending.site_location }}</label
                            >
                          </div>
                        </div>
                        <div class="col-3">
                          <b-button
                            @click="editPending(itempending)"
                            style="
                              background: #0dc41f;
                              color: #2f2f37;
                              border-color: #0dc41f;
                            "
                            >Process</b-button
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div
                            style="
                              margin-left: 32px;
                              margin-top: 2px;
                              margin-bottom: 12px;
                            "
                          >
                            <label style="font-weight: normal">
                              {{ itempending.problem }} -
                              {{ format_date(itempending.created_at) }}</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <b-modal
                  v-model="editearly"
                  size="xl"
                  ref="my-modaledit"
                  title="Update Ticket for Early Progress"
                  hide-footer
                  centered
                >
                  <b-form @submit.stop.prevent="submitEdit" class="mt-4">
                    <div class="row">
                      <div class="col-12">
                        <DxDataGrid
                          :ref="dataGridRefKey"
                          :data-source="dataSource"
                          key-expr="id"
                          :show-borders="true"
                          :word-wrap-enabled="false"
                          :column-auto-width="false"
                          :remote-operations="true"
                          :column-hiding-enabled="false"
                          @row-click="getListTicket"
                        >
                          <DxColumn data-field="ticket_id" caption="Ticket ID" />
                          <DxColumn data-field="site_id" caption="Site ID" />
                          <DxColumn data-field="site_location" caption="Location" />
                          <DxColumn data-field="problem" caption="Problem" />
                          <DxColumn
                            data-field="created_at"
                            caption="Tanggal"
                            data-type="date"
                            format="dd/MM/yyyy HH:mm:ss"
                          />
                          <DxColumn data-field="status" caption="Status" />
                          <DxColumn :width="100" type="buttons" caption="Aksi">
                            <DxButton icon="fa fa-edit" hint="Edit" :onClick="editTiket" />
                          </DxColumn>
                          <DxPaging :page-size="10" />
                          <DxPager
                            :visible="true"
                            :show-page-size-selector="showPageSizeSelector"
                            :allowed-page-sizes="pageSizes"
                          />
                        </DxDataGrid>
                      </div>
                    </div>
                    <div class="row mt-2" v-if="showHistoryTicket">
                      <div class="col-12">
                        <div class="card card-custom">
                          <div class="card-header">
                            <div class="card-title">
                              <label>Ticket History {{ ticketSelected ? ': '+ticketSelected.ticket_id : '' }}</label>
                            </div>
                          </div>
                          <div class="card-body">
                            <DxDataGrid
                              :ref="dataGridRefKeyHistory"
                              :data-source="dataSourceHistoryTicket"
                              key-expr="id"
                              :show-borders="true"
                              :word-wrap-enabled="false"
                              :column-auto-width="false"
                              :remote-operations="true"
                              :column-hiding-enabled="false">
                              <DxColumn data-field="ticket_id" caption="Ticket ID" />
                              <DxColumn data-field="category" caption="Category" />
                              <DxColumn data-field="subcategory" caption="Sub Category" />
                              <DxColumn data-field="description" caption="Decsription" />
                              <DxColumn
                                  data-field="updated_at"
                                  caption="Tanggal"
                                  data-type="date"
                                  format="dd/MM/yyyy HH:mm:ss"
                              />
                              <DxColumn data-field="user.role.name" caption="Updated By" />
                            </DxDataGrid>
                            <DxPaging
                            :page-size="5"
                            :page-index="0"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group mt-15">
                      <h4>Support</h4>
                      <b-form-checkbox
                        v-model="isSupport"
                        value="1"
                        unchecked-value="0"
                        switch
                        size="lg"
                      >
                        *If you switch to the support, this ticket will be
                        handled by the support team.
                      </b-form-checkbox>
                    </div>
                    <!-- <div class="row">
                      <div class="form-group col-6">
                        <b-form-group
                          id="example-input-group-role"
                          label-for="select-role"
                        >
                          <template v-slot:label>
                            Category <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            id="select-modal-categories"
                            v-model="$v.formprocess.category.$model"
                            :options="categories2"
                            :state="validateStateProcess('category')"
                            v-on:change="getCategory"
                            aria-describedby="input-role-live-feedback"
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled
                                >-- Pilih Salah Satu --</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </div>
                      <div class="form-group col-6">
                        <b-form-group
                          id="example-input-group-role"
                          label-for="select-role"
                        >
                          <template v-slot:label>
                            Sub Category <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            id="select-sub-categories"
                            :disabled="formprocess.category == ''"
                            v-model="$v.formprocess.subcategory.$model"
                            :options="SubCategories"
                            :state="validateStateProcess('subcategory')"
                            aria-describedby="input-role-live-feedback"
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled
                                >-- Pilih Salah Satu --</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for=""
                        >Description: <span class="text-danger">*</span></label
                      >
                      <b-form-textarea
                        id="deskripsi"
                        v-model="$v.formprocess.description.$model"
                        :state="validateStateProcess('description')"
                        aria-describedby="input-1-live-feedback"
                        placeholder="Fill out this form..."
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </div> -->

                    <div class="d-flex justify-content-center">
                      <!-- <b-button
                        class="mr-5"
                        type="submit"
                        :disabled="isSupport == 1"
                        @click="action = 'update'"
                        >Update</b-button
                      > -->
                      <b-button
                        class="btn btn-danger"
                        type="submit"
                        @click="action = 'closed'"
                        >Close Ticket</b-button
                      >
                    </div>
                  </b-form>
                  <!-- <pre>{{ infoModal.content }}</pre> -->
                </b-modal>
                <b-modal
                  ref="my-modaladd"
                  v-model="modalShow"
                  size="lg"
                  title="Create New Ticket"
                  @show="modalShow"
                  @hidden="oncancelAdd()"
                  @ok="handleOk"
                  hide-footer
                >
                  <b-form @submit.stop.prevent="onSubmit">
                    <div class="row">
                      <div class="form-group col-6">
                        <label for
                          >Site ID <span class="text-danger">*</span></label
                        >
                        <b-form-select
                          id="siteid"
                          name="siteid"
                          :options="optionssite"
                          v-on:change="getSiteId"
                          :state="validateState('siteid')"
                          aria-describedby="input-role-live-feedback"
                        >
                          <template #first>
                            <b-form-select-option :value="null" disabled
                              >-- Pilih Salah Satu --</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </div>

                      <div class="form-group col-6">
                        <label for
                          >Site Location
                          <span class="text-danger">*</span></label
                        >
                        <b-form-input
                          id="sitelocation"
                          disabled="true"
                          name="sitelocation"
                          v-model="$v.form.sitelocation.$model"
                          :state="validateState('sitelocation')"
                          aria-describedby="input-1-live-feedback"
                          placeholder="Site Location"
                          type="text"
                          class="form-control"
                        ></b-form-input>
                      </div>

                      <div class="form-group col-6">
                        <label for
                          >Created Date
                          <span class="text-danger">*</span></label
                        >
                        <b-form-datepicker
                          id="datepicker"
                          name="last_date"
                          v-model="$v.form.last_date.$model"
                          :state="validateState('last_date')"
                          aria-describedby="input-1-live-feedback"
                          class="mb-2"
                        ></b-form-datepicker>
                      </div>
                      <div class="form-group col-6">
                        <label for
                          >Problem <span class="text-danger">*</span></label
                        >
                        <b-form-input
                          id="problem"
                          name="problem"
                          v-model="$v.form.problem.$model"
                          placeholder="Problem"
                          :state="validateState('problem')"
                          aria-describedby="input-1-live-feedback"
                          type="text"
                          class="form-control"
                        ></b-form-input>
                      </div>
                      <div class="form-group col-6">
                        <b-form-group
                          id="example-input-group-role"
                          label-for="select-role"
                        >
                          <template v-slot:label>
                            Category <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            id="select-categories-add"
                            v-model="$v.form.category.$model"
                            :state="validateState('category')"
                            aria-describedby="input-role-live-feedback"
                            v-on:change="getCategory"
                            :options="categories"
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled
                                >-- Pilih Salah Satu --</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </div>
                      <div class="form-group col-6">
                        <b-form-group
                          id="example-input-group-role"
                          label-for="select-role"
                        >
                          <template v-slot:label>
                            Sub Category <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            id="select-subb-categories-3"
                            :disabled="form.category == ''"
                            v-model="$v.form.subcategory.$model"
                            :state="validateState('subcategory')"
                            :options="SubCategories"
                            aria-describedby="input-role-live-feedback"
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled
                                >-- Pilih Salah Satu --</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </div>
                    </div>
                    <b-card-footer>
                      <div class="d-flex justify-content-center">
                        <b-button
                          type="submit"
                          @click="handleOk()"
                          class="btn btn-danger"
                          >Simpan Data</b-button
                        >
                      </div>
                    </b-card-footer>
                  </b-form>
                  <!-- <pre>{{ infoModal.content }}</pre> -->
                </b-modal>
                <!-- MODAL EDIT TIKET -->
                <!-- Modal Ticket Histroy -->
                <b-modal
                  v-model="showFormUpdateTicket"
                  size="xl"
                  ref="my-modalFormUpdateTicket"
                  title="Update Ticket"
                  hide-footer
                  centered
                >
                  <b-form @submit.stop.prevent="submitUpdateTicket" class="mt-4">
                    <div class="row">
                      <div class="form-group col-6">
                        <b-form-group
                          id="example-input-group-role"
                          label-for="select-role"
                        >
                          <template v-slot:label>
                            Category <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            id="select-modal-categories"
                            v-model="$v.formUpdateTicket.category_id.$model"
                            :options="categories2"
                            :state="validateStateUpdateTicket('category_id')"
                            v-on:change="getCategory"
                            aria-describedby="input-role-live-feedback"
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled
                                >-- Pilih Salah Satu --</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </div>
                      <div class="form-group col-6">
                        <b-form-group
                          id="example-input-group-role"
                          label-for="select-role"
                        >
                          <template v-slot:label>
                            Sub Category <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            id="select-sub-categories"
                            :disabled="formUpdateTicket.category_id == 0"
                            v-model="$v.formUpdateTicket.subcategory_id.$model"
                            :options="SubCategories"
                            :state="validateStateUpdateTicket('subcategory_id')"
                            aria-describedby="input-role-live-feedback"
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled
                                >-- Pilih Salah Satu --</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for=""
                        >Description: <span class="text-danger">*</span></label
                      >
                      <b-form-textarea
                        id="deskripsi"
                        v-model="$v.formUpdateTicket.description.$model"
                        :state="validateStateUpdateTicket('description')"
                        aria-describedby="input-1-live-feedback"
                        placeholder="Fill out this form..."
                        rows="3"
                        max-rows="6"
                        required
                      ></b-form-textarea>
                    </div>

                    <div class="d-flex justify-content-center">
                      <b-button
                        class="mr-5"
                        type="submit"
                        >Update</b-button
                      >
                    </div>
                  </b-form>
                  <!-- <pre>{{ infoModal.content }}</pre> -->
                </b-modal>
              </div>
              <div class="card-footer text-muted">
                <center>
                  <router-link to="/viewpendingtiket">
                    <label style="font-weight: normal;cursor: pointer;">
                      Lihat Semua</label
                    >
                  </router-link>
                </center>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div>
            <site_list />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import site_list from "@/components/ticketing/site_list.vue";
import Timon from "@/components/ticketing/Timon.vue";
import WebService from "../../core/services/api";
import EventBus from "../../core/services/common/EventBus";
import Swal from "sweetalert2";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { DxDataGrid, DxColumn, DxButton, DxPaging, DxPager } from "devextreme-vue/data-grid";
import { isNotEmpty } from "../../core/Apputil/Dx";
import CustomStore from "devextreme/data/custom_store";
moment.locale("id");
const dataGridRefKey = "my-data-grid";
const dataGridRefKeyHistory = "my-data-grid-histrory";
export default {
  mixins: [validationMixin],
  components: {
    DxDataGrid,
    DxColumn,
    Timon,
    site_list,
    DxButton,
    DxPaging,
    DxPager
  },
  data() {
    return {
      form: {
        sitelocation: "",
        siteid: "",
        last_date: "",
        problem: "",
        category: "",
        subcategory: ""
      },
      formprocess: {
        description: "",
        category: "",
        subcategory: ""
      },
      formUpdateTicket: {
        id: 0,
        created_at: "",
        updated_at: "",
        ticket_id: "",
        site_id: "",
        site_location: "",
        category_id: 0,
        category: "",
        subcategory_id: 0,
        subcategory: "",
        created_date: "",
        last_visit: "",
        problem: "",
        description: "",
        status: "",
        box_sn: "",
        ditutup_via: "",
        ticket_id_penutup: "",
        closed_at: "",
        old_ticket_id: ""
      },
      dataGridRefKey,
      dataGridRefKeyHistory,
      editPendingDetail: {},
      selected: null,
      options: [],
      subkategori_id: "",
      kategori_id: "",
      subkategori_id_edit: "",
      kategori_id_edit: "",
      loading: false,
      nextItem: 1,
      dataSource: [],
      currentSerial: null,
      siteidtxt: "",
      detail: false,
      subcat: "",
      deskripsi: "",
      camerabase64: "",
      currentSiteId: "",
      idTiket: "",
      categories: [],
      categories2: [],
      SubCategories: [],
      optionspending: [
        { value: true, text: "Terbaru" },
        { value: false, text: "Terlama" }
      ],
      selectedpending: true,
      itemseditearly: [],
      fieldseditearly: [
        {
          key: "user",
          label: "User",
          class: "text-center"
        },
        {
          key: "alert",
          label: "Alert",
          sortable: false,
          class: "text-center"
        },
        {
          key: "old_data",
          label: "Category",
          sortable: false,
          class: "text-center"
        },
        {
          key: "created_at",
          label: "Created At",
          sortable: false,
          class: "text-center"
        },
        {
          key: "action2",
          label: "Time of Update",
          sortable: false,
          class: "text-center"
        },
        {
          key: "action1",
          label: "PIC / Operator",
          sortable: false,
          class: "text-center"
        },
        {
          formatter: value => {
            return value ? "Yes" : "No";
          },

          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        }
      ],
      isSupport: 0,
      itemsuser: [],
      optionssite: [],
      itempending: [],
      detailTiket: {
        created_at: "2022-01-01T00:00:00Z",
        created_date: "2022-01-01T00:00:00Z",
        id: "-",
        last_visit: "2022-01-01T00:00:00Z",
        problem: "-",
        site_id: "-",
        site_location: "-",
        status: "-",
        box_sn: "-",
        ticket_id: "-",
        wsid: "",
        site_class_name: "",
        address: "",
        province: "",
        district: "",
        zip_code: "",
        pkt: ""
      },
      action: null,
      modalShow: false,
      editearly: false,
      interval: null,
      showFormUpdateTicket: false,
      ticketSelected: null,
      dataSourceHistoryTicket: [],
      showHistoryTicket: false
    };
  },
  validations: {
    form: {
      siteid: {
        required
      },
      sitelocation: {
        required
      },
      last_date: { required },
      problem: { required },
      category: { required },
      subcategory: { required }
    },
    formprocess: {
      // description: { required },
      // category: { required },
      // subcategory: { required },
    },
    formUpdateTicket: {
      id: { required },
      ticket_id: { required },
      site_id: { required },
      site_location: { required },
      category_id: { required },
      subcategory_id: { required },
      problem: { required },
      description: { required },
      ticket_id_penutup: { required }
    }
  },
  mounted() {
    const listElm = document.querySelector("#infinite-list");
    listElm.addEventListener("scroll", e => {
      console.log(
        e,
        listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight
      );
      if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        this.loadMore();
      }
    });
    this.getDataTablenew();
    this.dataDataPending();
    this.getLocalsite();
    this.getCategories();
    // this.getSubCategories();
  },
  methods: {
    editTiket(e) {
      const ticketSelected = e.row.data;
      this.ticketSelected = null;
      this.formUpdateTicket = Object.assign({}, this.formUpdateTicket, ticketSelected);
      this.formUpdateTicket.category_id = 0;
      this.formUpdateTicket.subcategory_id = 0;
      this.formUpdateTicket.description = "";
      this.showFormUpdateTicket = true;
      this.showHistoryTicket = false;
    },
    submitUpdateTicket(e){
      e.preventDefault();
      const category = this.categories.find(f => f.value == this.formUpdateTicket.category_id);
      const subcategory = this.SubCategories.find(f => f.value == this.formUpdateTicket.subcategory_id);
      this.formUpdateTicket.category = category.text;
      this.formUpdateTicket.subcategory = subcategory.text;
      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.putNewTicket(this.formUpdateTicket.id, this.formUpdateTicket)
            .then(data => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil diubah",
                showConfirmButton: false,
                timer: 1500
              }).finally(() => {
                if(data.data.data.id){
                  this.getListTicket(data.data);
                }
              });
              this.$v.formUpdateTicket.$reset();
              this.$refs["my-modalFormUpdateTicket"].hide();
              this.showFormUpdateTicket = false;
              this.showHistoryTicket = true;
            })
            .catch(() => {
              Swal.fire({
                title: "Kesalahan",
                text: "Terjadi Kesalahan",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    getListTicket(e) {
      this.ticketSelected = e.data;
      this.showHistoryTicket = true;
      const store = new CustomStore({
        key: "id",
        load: function(loadOptions) {
          loadOptions['take'] = 1000;
          let params = "?";
          [
            "skip",
            "take",
            "requireTotalCount",
            "requireGroupCount",
            "sort",
            // "filter",
            "totalSummary",
            "group",
            "groupSummary"
          ].forEach(function(i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) {
              params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            }
          });
          params = params.slice(0, -1);

          // search by name if exists

          if (
            Array.isArray(loadOptions["filter"]) &&
            loadOptions["filter"].length > 0 &&
            loadOptions["filter"][0] === "name"
          ) {
            params += "&nama=" + loadOptions["filter"][2];
          }
          const user = JSON.parse(localStorage.getItem("user"));
          const headers = { Authorization: `Bearer ${user.access_token}` };
          return fetch(
            process.env.VUE_APP_URL_LOCAL +
              `/ticket-new/history/${e.data.id}${params}`,
            {
              headers
            }
          )
            .then(response => response.json())
            .then(data => {
              let tempdata = [];
              if(data.data?.length > 0){
                for(let i = data.data?.length - 1; i >= 0; i--){
                  let tempPush = { ...data.data[i].new_data, user: JSON.parse(data.data[i].user)};
                  tempdata.push(tempPush);
                }
              }
              // if(tempdata.length == 1){
              //   tempdata = [];
              // } else {
              //   tempdata = tempdata.slice(0, tempdata.length -1);
              // }
              return {
                data: tempdata,
                summary: data.summary,
                totalCount: data.totalCount
              };
            })
            .catch(() => {
              throw "Data Loading Error";
            });
        },
        onLoaded: (resp) => {
          if(resp.data?.length > 0){
            resp.data.map((fd) => {
              if(fd.sub_category){
                fd['subcategory'] = fd.sub_category.name;
                if(fd.sub_category.category){
                  fd['category'] = fd.sub_category.category.name
                }
              } else {
                fd['category'] = '';
                fd['subcategory'] = '';
              }
            });
          }
        }
      });
      this.dataSourceHistoryTicket = store;
    },
    format_date(value) {
      if (value) {
        return moment(String(value))
          .lang("id")
          .format("DD MMMM YYYY HH:mm");
      }
    },
    showModalLive() {
      this.$refs["my-modal"].show();
    },
    pendingFilter() {
      console.log(this.selectedpending);
      this.dataDataPending();
    },
    getLocalsite() {
      WebService.getlocalsiteref().then(async res => {
        const roles = res.data.data;
        let selectedOptions;
        selectedOptions = [];
        roles.map(i => {
          return selectedOptions.push({
            text: i.site_id,
            value: i
          });
        });
        this.optionssite = selectedOptions;
      });
    },
    searchAction() {
      console.log(this.siteidtxt);
      let databody = this.siteidtxt;
      WebService.getPendingTicketSearch(databody).then(
        res => {
          this.itempending = res.data.data;
        },
        error => {
          console.log(error);
          this.itempending = [];
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    Camerathumbnail(val) {
      let serial = {
        serial: val
      };
      WebService.apiCamera(serial).then(
        res => {
          this.camerabase64 = res.data;
        },
        error => {
          console.log(error);
          if (error.code === "ECONNABORTED") {
            console.log("timeout");
            this.camerabase64 = null;
            this.currentSerial = null;
          }
          if (error.response.status === 500) {
            this.camerabase64 = null;
            this.currentSerial = null;
          }
          // this.itempending = [];
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    getUser(data) {
      return JSON.parse(data.user).role.name;
    },
    getOperator(data) {
      return JSON.parse(data.user).email;
    },
    getDesc(data) {
      return JSON.parse(data.new_data).description;
    },
    getAlert(data) {
      return JSON.parse(data.old_data).problem;
    },
    getStatus(data) {
      return JSON.parse(data.old_data).status;
    },
    getCreatedat(data) {
      return moment(String(data.created_at))
        .lang("id")
        .format("DD MMMM YYYY HH:mm");
    },
    getUpdated(data) {
      return moment(JSON.parse(data.old_data).updated_at)
        .lang("id")
        .format("DD MMMM YYYY HH:mm");
    },
    oncancelAdd() {
      this.modalShow = false;
      this.kategori_id = "";
      this.subkategori_id = "";
      this.resetForm();
    },
    getSiteId(data) {
      this.form = {
        sitelocation: data.location_name,
        siteid: data.site_id,
        problem: this.form.problem,
        last_date: this.form.last_date,
        category: this.form.category,
        subcategory: this.form.subcategory
      };
    },
    getCategory(id) {
      WebService.getSubCategoryById(id).then(
        res => {
          const SubCategories = res.data.data;
          let selectedOptions;
          selectedOptions = [];
          SubCategories.map(i => {
            return selectedOptions.push({
              text: i.name,
              value: i.id
            });
          });
          this.SubCategories = selectedOptions;
        },
        err => {
          console.log(err);
        }
      );
    },
    submitEdit() {
      this.$v.formprocess.$touch();
      if (this.$v.formprocess.$anyError) {
        return;
      }
      const id = this.editPendingDetail.id;
      const jsondata = {
        status: this.action == "update" ? "pending" : "closed",
        description: this.formprocess.description,
        category_id: this.formprocess.category,
        subcategory_id: this.formprocess.subcategory
      };
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Yakin ?.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          console.log(this.action);
          if (this.isSupport == 1 && this.action == "closed") {
            return WebService.convertTotiketSupport(id).then(
              res => {
                console.log(res);
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Data berhasil Ditambahkan",
                  showConfirmButton: false,
                  timer: 1500
                });
                this.dataDataPending();
                (this.deskripsi = ""),
                  (this.categories = ""),
                  (this.editearly = false);
              },
              error => {
                console.log(error);
                Swal.fire({
                  title: "Kesalahan",
                  text: "Data Gagal Ditambahkan",
                  icon: "error",
                  showCancelButton: false,
                  confirmButtonText: "Kembali",
                  confirmButtonColor: "#18328D"
                });
                this.editearly = false;
                (this.deskripsi = ""),
                  (this.categories = ""),
                  this.dataDataPending();
              }
            );
          } else {
            return WebService.editTiketPending(id, jsondata)
              .then(() => {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Data berhasil Ditambahkan",
                  showConfirmButton: false,
                  timer: 1500
                });
                this.dataDataPending();
                (this.deskripsi = ""),
                  (this.categories = ""),
                  (this.editearly = false);
              })
              .catch(error => {
                console.log(error);
                Swal.fire({
                  title: "Kesalahan",
                  text: "Data Gagal Ditambahkan",
                  icon: "error",
                  showCancelButton: false,
                  confirmButtonText: "Kembali",
                  confirmButtonColor: "#18328D"
                });
                this.editearly = false;
                (this.deskripsi = ""),
                  (this.categories = ""),
                  this.dataDataPending();
              });
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    viewAll() {
      console.log("view all");
    },
    editPending(item) {
      this.isSupport = 0;
      this.ticketSelected = null;
      this.showHistoryTicket = false;
      this.$v.formprocess.$reset();
      this.idTiket = item.id;
      const store = new CustomStore({
        key: "id",
        load: function(loadOptions) {
          let params = "?";
          [
            "skip",
            "take",
            "requireTotalCount",
            "requireGroupCount",
            "sort",
            // "filter",
            "totalSummary",
            "group",
            "groupSummary"
          ].forEach(function(i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) {
              params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            }
          });
          params = params.slice(0, -1);

          // search by name if exists

          if (
            Array.isArray(loadOptions["filter"]) &&
            loadOptions["filter"].length > 0 &&
            loadOptions["filter"][0] === "name"
          ) {
            params += "&nama=" + loadOptions["filter"][2];
          }
          const user = JSON.parse(localStorage.getItem("user"));
          const headers = { Authorization: `Bearer ${user.access_token}` };
          return fetch(
            process.env.VUE_APP_URL_LOCAL +
              `/ticket-process-items/${item.id}${params}`,
            {
              headers
            }
          )
            .then(response => response.json())
            .then(data => {
              return {
                data: data.data ? data.data : [],
                totalCount: data.totalCount,
                summary: data.summary,
                groupCount: data.groupCount
              };
            })
            .catch(() => {
              throw "Data Loading Error";
            });
        }
      });
      this.dataSource = store;
      this.editPendingDetail = item;
      this.editearly = true;
      this.getCategory(item.category_id);
      this.deskripsi = item.description;
      this.kategori_id_edit = item.category_id;
      this.subkategori_id_edit = item.subcategory_id;
      this.formprocess.description = item.description;
      this.formprocess.category = item.category_id;
      this.formprocess.subcategory = item.subcategory_id;
      // WebService.gethistoryPending(item.id)
      //   .then(res => {
      //     this.itemseditearly = res.data.data;
      //   })
      //   .catch(error => {
      //     console.log(error);
      //     this.itemseditearly = [];
      //   });
    },
    selectTicketDetail(value) {
      console.log(value, "detail");
      this.camerabase64 = null;
      this.currentSerial = value.box_sn;
      if (value.box_sn != "") {
        this.Camerathumbnail(value.box_sn);
      }
      this.detailTiket = value;
      WebService.getlocalsiteBySiteid(this.detailTiket.site_id).then(resp => {
        if(resp.data?.data){
          this.detailTiket.wsid = resp.data.data.wsid;
          this.detailTiket.site_class_name = resp.data.data.site_class_name;
          this.detailTiket.address = resp.data.data.address;
          this.detailTiket.province = resp.data.data.province;
          this.detailTiket.district = resp.data.data.district;
          this.detailTiket.zip_code = resp.data.data.zip_code;
          this.detailTiket.pkt = resp.data.data.pkt;
        }
      });
    },
    selectTiket(value) {
      const id = value.id;
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Yakin ?.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.editTiketPendingNew(id)
            .then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil Ditambahkan",
                showConfirmButton: false,
                timer: 1500
              });
              this.dataDataPending();
              this.getDataTablenew();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text:
                  "Sudah ada tiket process Id yang masih belum ditutup untuk site_id ini!",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
              this.getDataTablenew();
              this.dataDataPending();
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    dataDataPending() {
      let databody = JSON.stringify([
        { selector: "created_at", desc: this.selectedpending }
      ]);
      WebService.getPendingTicket(encodeURI(databody)).then(
        res => {
          this.itempending = res.data.data;
        },
        error => {
          console.log(error);
          this.itempending = [];
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    loadMore() {
      console.log("load");
      this.loading = true;
      setTimeout(e => {
        for (var i = 0; i < 20; i++) {
          console.log(this.nextItem++);
        }
        console.log(e, this.nextItem, "skipppp");
        this.getdataLoad();
        this.loading = false;
      }, 200);
    },

    getDataTablenew() {
      this.loading = true;
      let databody = JSON.stringify([{ selector: "created_at", desc: true }]);
      WebService.getnewTicketProcess(encodeURI(databody), 0).then(
        res => {
          this.itemsuser = res.data.data;
          this.nextItem = 1;
          this.totalRows = res.data.totalCount;
          this.loading = false;
        },
        error => {
          console.log(error);
          this.itemsuser = [];
          this.totalRows = 0;
          this.loading = false;
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
      this.loading = false;
    },
    getdataLoad() {
      this.loading = true;
      let databody = JSON.stringify([{ selector: "created_at", desc: true }]);
      WebService.getnewTicketProcess(encodeURI(databody), this.nextItem).then(
        res => {
          this.itemsuser.push(...this.itemsuser, ...res.data.data);
          this.totalRows = res.data.totalCount;
          this.loading = false;
        },
        error => {
          console.log(error);
          this.itemsuser = [];
          this.totalRows = 0;
          this.loading = false;
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
      this.loading = false;
    },
    getCategories() {
      WebService.getCategoryAll().then(
        res => {
          const categories = res.data.data;
          let selectedOptions;
          selectedOptions = [];
          categories.map(i => {
            return selectedOptions.push({
              text: i.name,
              value: i.id
            });
          });
          this.categories = selectedOptions;
          this.categories2 = selectedOptions;
        },
        err => {
          console.log(err);
        }
      );
    },
    getSubCategories() {
      WebService.getSubCategory().then(
        res => {
          const SubCategories = res.data.data;
          let selectedOptions;
          selectedOptions = [];
          SubCategories.map(i => {
            return selectedOptions.push({
              text: i.name,
              value: i.id
            });
          });
          this.SubCategories = selectedOptions;
        },
        err => {
          console.log(err);
        }
      );
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateProcess(name) {
      const { $dirty, $error } = this.$v.formprocess[name];
      return $dirty ? !$error : null;
    },
    validateStateUpdateTicket(name) {
      const { $dirty, $error } = this.$v.formUpdateTicket[name];
      return $dirty ? !$error : null;
    },
    hideModaledit() {
      this.$refs["my-modaledit"].hide();
    },
    hideModal() {
      this.$refs["my-modaladd"].hide();
      this.kategori_id = "";
      this.subkategori_id = "";
    },
    resetForm() {
      this.form = {
        sitelocation: "",
        siteid: "",
        problem: "",
        last_date: ""
      };
    },
    randomstr(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.onSubmit();
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let unixTime = Math.floor(Date.now() / 1000);
      let data = {
        site_id: this.form.siteid,
        ticket_id: unixTime + "." + this.form.siteid + "." + this.randomstr(6),
        site_location: this.form.sitelocation,
        last_visit: this.form.last_date + "T00:00:00Z",
        status: "pending",
        category_id: this.form.category,
        subcategory_id: this.form.subcategory,
        problem: this.form.problem
      };
      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.addTiketPending(data)
            .then(data => {
              console.log(data);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil ditambah",
                showConfirmButton: false,
                timer: 1500
              });
              this.$v.form.$reset();
              this.resetForm();
              this.hideModal();
              this.getDataTablenew();
              this.dataDataPending();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Terjadi Kesalahan",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    }
  },
  created() {
    this.interval = setInterval(() => {
      console.log(this.currentSerial);
      this.getDataTablenew();
      if (this.currentSerial !== null) {
        this.Camerathumbnail(this.currentSerial);
      }
    }, 5000);
  },
  destroyed() {
    clearInterval(this.interval);
  }
};
</script>

<style>
.anyClasspend {
  height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}
.live {
  background: unset !important;
  border: unset !important;
  outline: unset !important;
}

.videos {
  justify-content: center;
  display: flex;
}
.loading {
  text-align: center;
  position: absolute;
  color: #fff;
  z-index: 9;
  background: gray;
  padding: 8px 18px;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}
</style>
